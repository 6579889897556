<form [formGroup]="model.form" class="flex f-fd--column f-jc--center howden-dialog-content text-center">
  @if (model.roleId) {
    <div mat-dialog-title class="title-text" i18n="@@app.edit.role.title">Modificación de rol</div>
  } @else {
    <div mat-dialog-title class="title-text" i18n="@@app.create.role.title">Alta de rol</div>
  }

  <mat-dialog-content>
    <div class="flex">
      <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.edit.role.dialog.name.label">Nombre</mat-label>
        <input matInput [formControlName]="model.C_NAME" [howdenControlError]="roleNameError" maxlength="100" />
        <mat-error #roleNameError></mat-error>
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-slide-toggle
        class="f-basis--12"
        [formControlName]="model.C_IS_ADMIN"
        i18n="@@app.edit.role.dialog.is-admin.label"
      >
        Administrador
      </mat-slide-toggle>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>

    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
