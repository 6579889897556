<form [formGroup]="model.form" class="flex f-fd--column f-jc--center howden-dialog-content text-center">
  @if (model.companyID) {
    <div mat-dialog-title class="title-text" i18n="@@app.edit.company.dialog.edit.title">Modificación de empresas</div>
  } @else {
    <div mat-dialog-title class="title-text" i18n="@@app.edit.company.dialog.create.title">Alta de empresas</div>
  }

  <mat-dialog-content>
    <div class="flex">
      <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.edit.company.dialog.name.label">Nombre</mat-label>
        <input matInput [formControlName]="model.C_COMPANY_NAME" [howdenControlError]="companyNameError" maxlength="100" />
        <mat-error #companyNameError></mat-error>
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-form-field class="f-basis--12">
        <mat-select [formControlName]="model.C_EXTERNAL_APP_ID">
          @for (extapp of model.externalApps; track extapp) {
            <mat-option [value]="extapp.externalAppID">
              {{ extapp.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>

    <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
