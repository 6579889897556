<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field>
        <mat-label i18n="@@app.users.search.name.label">Nombre</mat-label>
        <input #nameInput matInput [formControlName]="model.C_NAME" maxlength="100" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.users.search.surname.label">Apellidos</mat-label>
        <input #nameInput matInput [formControlName]="model.C_SURNAME" maxlength="250"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.users.search.email.label">Email</mat-label>
        <input #nameInput matInput [formControlName]="model.C_EMAIL" maxlength="100"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.users.search.username.label">Usuario</mat-label>
        <input #nameInput matInput [formControlName]="model.C_USERNAME" maxlength="256"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@app.users.search.status.label">Estado</mat-label>
        <mat-select [formControlName]="model.C_STATUS">
          <mat-option value=""></mat-option>
          <mat-option value="1" i18n="@@app.users.search.active-status.label">Activo</mat-option>
          <mat-option value="0" i18n="@@app.users.search.inactive-status.label">Inactivo</mat-option>
        </mat-select>
      </mat-form-field>

      <button
        type="button"
        mat-mini-fab
        color="primary"
        matTooltip="Alta de usuario"
        i18n-matTooltip="@@app.users.create"
        (click)="onRegisterUser()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </form>
  <div>
    <howden-table
      keyField="id"
      paginable="server"
      sortable="server"
      filterable="server"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      (buttonClicked)="onAction($event)"
    >
    </howden-table>
  </div>
</div>
